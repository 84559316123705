import { Auth } from "aws-amplify";

import { FormGroup, FormControl, ControlLabel } from "react-bootstrap";
import LoaderButton from "../components/LoaderButton";
import React, { Component } from "react";
import NavBar from "./NavBar";
import "../dist/css/style.css";
import "./Signin.css";
import BaseComponent from "../classes/BaseComponent"





export default class Login extends BaseComponent {
  constructor(props) {
    super(props);
    
    this.pageaudience="public";

	this.state = {
      email: ""
      ,password: ""
	  ,isLoading: false
      ,token: ""
      ,msg:""
      ,collectchallenge:false
      ,confirmcode:""
      ,isConfirming: false
      ,user:null
      ,isConfirmFocused:false
      ,mfatype:null
    };
  
    this.oncomponentmounted = async (susr)=> {
        //do stuff
      
    };
}
  

  validateForm() {
    return this.state.email.length > 0 && this.state.password.length > 0;
  }

  handleChange = event => {
    this.setState({
      [event.target.id]: event.target.value
    });
  }

  handleSubmit = async event => {
	  event.preventDefault();

	  this.setState({ isLoading: true, msg:'' });
	  
	  try {
        var result = await Auth.signIn(this.state.email, this.state.password);
         this.setState({user:result});
         this.props.userHasAuthenticated(true);
         Auth._storage.setItem("ActiveSession",true,true);
         
        switch(result.challengeName){
            case "SMS_MFA":
                //challengeParam
                //Auth.confirmSignIn(result,code)
               
                this.setState({ isLoading: false, mfatype:result.challengeName });
                this.showChallengeForm();

            break;
            case "SOFTWARE_TOKEN_MFA":
            	this.setState({ isLoading: false, mfatype:result.challengeName });
                this.showChallengeForm();
            break;
            default:
            	this.completesignin();
            break;
        }
      
	  } catch (e) {
		
		this.setState({ isLoading: false, msg: e.message}) ;
	  }
	}

    handleConfirmationCode = async event =>{
        event.preventDefault();
        this.setState({ isConfirming: true});
     
     try {
        
        var that = this;
        
            var usr = this.state.user;
            var code = this.state.confirmcode;
            var mfatype = this.state.mfatype;
            
            Auth.confirmSignIn(usr,code,mfatype).then(result=>{
                    
                    that.completesignin()

            }).catch(function(e){
                 that.setState({msg:'Failed confirming signin' + e.message})
            });
        
        
	  } catch (e) {
            this.setState({ isConfirming: false,msg:e.message });
      }
      
      this.setState({ isConfirming: false} );

  }

  showChallengeForm(){

    this.setState({collectchallenge:true,isConfirmFocused:true});
    
  }

  completesignin(){

    this.props.userHasAuthenticated(true);
    Auth._storage.setItem("ActiveSession",true,true);
    
    this.setState({ isLoading: false });
    this.props.history.push("/dashboard");
    
  }

  render() {
    return (
		<div  >
			<NavBar  />  
			
			<div className="pa-0 ma-0 auth-page">
				<div className="container-fluid">
					<div className="table-struct full-width full-height">
						<div className="table-cell vertical-align-middle auth-form-wrap">
							<div className="auth-form  ml-auto mr-auto no-float">
								<div className="row">
                               	<div className="col-sm-12 col-xs-12">
                               		<div className="mb-30">
											<h3 className="text-center txt-dark mb-10">Sign in</h3>
											<h6 className="text-center nonecase-font txt-grey">Enter your details below</h6>
										</div>	
										
                                        <div className={(this.state.collectchallenge)?"form-wrap":"hidden"}>
                                            	<form onSubmit={this.handleConfirmationCode}>
												 <FormGroup controlId="confirm" bsSize="large"  className="form-group">
													<ControlLabel className="control-label mb-10 font-16">One Time Pass Code</ControlLabel>
													<FormControl
													  type="text"
                                                      id="confirmcode"
                                                      autoFocus={this.state.isConfirmFocused}
                                                      value={this.state.confirmcode}
													  onChange={this.handleChange}
                                                      className="form-control txt-primary block mb-10 pull-right font-16"
                                                      tabIndex="1"
													/>
												  </FormGroup>
												  
														
												  <div className="form-group text-center">
													<LoaderButton
													  block
													  bsSize="large"
                                                      disabled={(this.state.confirmcode.length!=6)}
                                                      type="submit"
													  isLoading={this.state.isConfirming}
													  text="Sign In"
													  loadingText="Signing In"
													  className="btn btn-info btn-primary btn-rounded"
													  tabIndex="3"
													/>  
												  </div>
											</form>
                                        </div>
										<div className={!(this.state.collectchallenge)?"form-wrap":"hidden"}>
                                         	<form onSubmit={this.handleSubmit}>
												 <FormGroup controlId="email" bsSize="large"  className="form-group">
													<ControlLabel className="control-label mb-10 font-16">Email</ControlLabel>
													<FormControl
													  autoFocus
													  type="email"
													  value={this.state.email}
													  onChange={this.handleChange}
                                                      className="form-control txt-primary block mb-10 pull-right font-16"
                                                      tabIndex="1"
													/>
												  </FormGroup>
												  <FormGroup controlId="password" bsSize="large" className="form-group">
													<ControlLabel className="pull-left control-label mb-10 font-16">Password</ControlLabel>
													<a className="capitalize-font txt-primary block mb-10 pull-right font-16" href="/forgotpassword">forgot password ?</a>
													<FormControl
													  value={this.state.password}
													  onChange={this.handleChange}
													  type="password"
                                                      className="form-control txt-primary block mb-10 pull-right font-16"
                                                      tabIndex="2"
													/>
												  </FormGroup>
														
												  <div className="form-group text-center">
													<LoaderButton
													  block
													  bsSize="large"
													  disabled={!this.validateForm()}
													  type="submit"
													  isLoading={this.state.isLoading}
													  text="Sign In"
													  loadingText="Signing in…"
													  className="btn btn-info btn-primary btn-rounded"
													  tabIndex="3"
													/>  
												  </div>
											</form>
										</div>
									</div>	
									<div className="messages">
	                                    {this.state.msg}
	                                </div>
								</div>
							</div>
						</div>
					</div>	
				</div>
			</div>
		</div>
    );
  }
}