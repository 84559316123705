import { Auth, API } from "aws-amplify";
import React, { Component, useHistory } from "react";
import { Route, Switch } from "react-router-dom";
import createHistory from 'history/createBrowserHistory'
const history = createHistory();




export default class BaseComponent extends Component {
	constructor(props) {
    super(props);
    
    
     this.state = {
	      isLoading: true
	      ,notes: []
	      ,err: ""
	      ,susr:null
	      
	     
    	};
   
    


    this.pageaudience = "private";       //property set my inherited pages to deicpher between public/private pages.  default to private
    
    //this.oncomponentmounted = null; //callback used from the componentDidMount function 

    
    this.delay = ms => new Promise(res => setTimeout(res, ms));
    this.delay1 = (async(t, v)=> {
    	   return new Promise(function(resolve) { 
    	       setTimeout(resolve.bind(null, v), t)
    	   });
    	});
    
    this.sessioncopied = false;
    this.initiatingcopy=false;
    this.sessionitemcount=-1;
    this.sessioncopyeventfired=false;
    //this.allowsessioncopy=(this.state.allowsessioncopy==false)?false:true;
    this.hprops = props;
    
    // listen for changes to localStorage
    if(window.addEventListener) {
      window.addEventListener("storage", this.sessionStorage_transfer, false);
    } else {
      window.attachEvent("onstorage", this.sessionStorage_transfer);
    };


   
    
    
    //while(!this.sessioncopied){
    	//this.delay(1000);
    //}
} 
  
  async componentDidMount() {
    
	  if(this.precomponentmounted)
		  this.precomponentmounted();
	  
	// Ask other tabs for session storage (this is ONLY to trigger event)
	  
	    if (Object.keys(sessionStorage).length==0 && this.allowsessioncopy()) {
	      
	      this.initiatingcopy = true;
	      localStorage.setItem('getSessionStorage', 'foobar');
	      localStorage.removeItem('getSessionStorage', 'foobar');
	    }/*else{
	    	localStorage.setItem('clearSessionStorage', 'foobar');
		    localStorage.removeItem('clearSessionStorage', 'foobar');
	    }*/
	    
	  /*while(!this.sessioncopied){
    	this.delay(1000);
    	alert('delay');
      }*/
	    
	    
	    
        switch(this.pageaudience){
            case "private":
                try{
                   //let susr = Auth.currentAuthenticatedUser();
                   //alert('checking current session');
                   var count=0;
                   if(Object.keys(sessionStorage).length == 0){
	                   while((!this.sessioncopyeventfired && count< 10) && (this.sessionitemcount != Object.keys(sessionStorage).length)){
	                	   await this.delay1(100);
	                	   
	                	   count++;
	                   }
                   }
                    let susr = await Auth.currentSession();
                    //if(Auth._storage.getItem("ActiveSession",true) && susr){
                    if(susr){
                    	this.refreshSession();
                    	this.setState({susr:susr});
                    	
                        if(this.oncomponentmounted){
                            this.oncomponentmounted(susr);
                        }
                    }else{
                        this.props.history.push("/signout");
                    }


                }catch(e){
                    
                    
                    (e === 'No current user' || e === 'not authenticated')		  
                       ? (this.props.history)?this.props.history.push("/signin"):window.location.href="/signin"
                       : alert(JSON.stringify(e));//this.props.history.push("/error");
                }

            break;
            case "public":
                this.oncomponentmounted();
            break;
            default:
                this.setState({"err":"invalid page audience.  check the value of this.pageaudience"});
                alert(JSON.stringify('invalid page audience'));//this.props.history.push("/error");
            break;
        }
  }
  
  
  
  getsigninurl(jwtToken, redirecturl){
	    
	    return API.put('getsigninurl', '/getsigninurl', { body:{ "jwtToken" : jwtToken, "redirect_url":redirecturl},headers:{}});
  }
  
  async doqsauth(susr){
	
	  var url=null;
	  if(!(Auth._storage.getItem("QuicksightActive_9997",true))){
          url = await this.getsigninurl(susr.idToken.jwtToken,'https://us-east-1.quicksight.aws.amazon.com/sn/start');
          Auth._storage.setItem("QuicksightActive_9997",true)
      }
	  return url;

  }
  
  setActiveState(params){
	  this.refreshSession();
	  this.setState(params);
  }
  
  refreshSession(){
	  Auth._storage.refresh(true);
  }
  
  preventsessioncopy(){
	  
	  this.setState({allowsessioncopy:false})
	  
	  
  };
  
  allowsessioncopy(){
	  return true;
  };
  oncomponentmounted(susr){
	  return false;
  };
  
  sessionStorage_transfer(event) {
	  // transfers sessionStorage from one tab to another
	   
      if(!event) { event = window.event; } // ie suq
      if(!event.newValue) {
    	  //alert('no values');
    	  return;          // do nothing if no value to work with
      }
      if (event.key == 'getSessionStorage') {
        // another tab asked for the sessionStorage -> send it
    	  //alert(JSON.stringify(sessionStorage));
        localStorage.setItem('sessionStorage', JSON.stringify(sessionStorage));
        // the other tab should now have it, so we're done with it.
        localStorage.removeItem('sessionStorage'); // <- could do short timeout as well.
      } else if (event.key == 'sessionStorage' && !sessionStorage.length) {
        // another tab sent data <- get it
        var data = JSON.parse(event.newValue);
        this.sessionitemcount = data.length;
        for (var key in data) {
          sessionStorage.setItem(key, data[key]);
        }
      }else if (event.key == 'clearSessionStorage'){
    	  sessionStorage.clear();
    	  history.go(0)
      }
      //alert('copied storage');
      this.sessioncopied = true;
      this.initiatingcopy = false;
      this.sessioncopyeventfired=true;
    };
   
    
}



