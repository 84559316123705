import React from "react";
import { Route, Switch } from "react-router-dom";
import Signin from "./containers/Signin";
import NotFound from "./containers/NotFound";
import AppliedRoute from "./components/AppliedRoute";
import Signup from "./containers/Signup";
import SignOut from "./containers/SignOut";
import ForgotPassword from "./containers/ForgotPassword";
import quicksight from "./containers/quicksight";
import dashboard from "./containers/Dashboard";
import domains from "./containers/domains";
import error from "./containers/error";
import managespf from "./containers/managespf"
import profile from "./containers/profile"
import spfinspector from "./containers/spfinspector"
import audittrails from "./containers/audittrails"


export default ({ childProps }) =>
  <Switch>
    
    {/*<AppliedRoute path="/audittrails" exact component={audittrails} props={childProps} /> */}
    <AppliedRoute path="/" exact component={Signin} props={childProps} />
    <AppliedRoute path="/signin" exact component={Signin} props={childProps} />
	<AppliedRoute path="/signout" exact component={SignOut} props={childProps} />
	<AppliedRoute path="/signup" exact component={Signup} props={childProps} />
    <AppliedRoute path="/forgotpassword" exact component={ForgotPassword} props={childProps} />
	<AppliedRoute path="/quicksight" exact component={quicksight} props={childProps} />
	<AppliedRoute path="/dashboard" exact component={dashboard} props={childProps} />
    <AppliedRoute path="/dashboard/:dashboardid" exact component={dashboard} props={childProps} />
    <AppliedRoute path="/policymanager" exact component={domains} props={childProps} />
    <AppliedRoute path="/error" exact component={error} props={childProps} />
    {/*<AppliedRoute path="/managespf/:hosteddomainid" exact component={managespf} props={childProps} />
    <AppliedRoute path="/managedmarc/:hosteddomainid" exact  component={NotFound} props={childProps} />*/}
    <AppliedRoute path="/profile" exact component={profile} props={childProps} />
    <AppliedRoute path="/domaininspector" exact component={spfinspector} props={childProps} />
    { /* Finally, catch all unmatched routes */ }    
    <Route component={NotFound} />
  </Switch>;